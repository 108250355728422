import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/test-chat',
      iconClass: 'fas fa-comments',
      name: '::Menu:TestChat',
      order: 200,
      parentName: 'AbpUiNavigation::Menu:Administration',
      requiredPolicy: 'LiveChat.Monitoring',
      layout: eLayoutType.application,
    },
  ]);
};

export const TEST_CHAT_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
