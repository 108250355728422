import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/queue',
      iconClass: 'fas fa-forward',
      name: '::Menu:Queues',
      order: 2,
      layout: eLayoutType.application,
      requiredPolicy: 'LiveChat.Queues',
    },
  ]);
};

export const QUEUES_QUEUE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
