import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/agent-state-intervals',
      iconClass: 'far fa-table',
      name: '::Menu:AgentStateIntervals',
      order: 304,
      parentName: '::Menu:AgentManagement',
      requiredPolicy: 'LiveChat.AgentStateIntervals',
    },
  ]);
};

export const AGENT_STATE_INTERVALS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
