import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/agents',
      iconClass: 'fas fa-users',
      name: '::Menu:Agents',
      order: 301,
      parentName: '::Menu:AgentManagement',
      requiredPolicy: 'LiveChat.Agents',
    },
  ]);
};

export const AGENTS_AGENT_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
