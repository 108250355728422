<div>
  <h2>{{ 'LiveChat::AgentMessagingSettings' | abpLocalization }}</h2>
  <hr />
  <form [formGroup]="form" *ngIf="isInitialized" (ngSubmit)="save()" validateOnSubmit>
    <div class="form-group">
      <label for="method">{{ 'LiveChat::AgentMessagingSettingsMethodsInfo' | abpLocalization }}</label>
      <ng-select [clearable]="false" [searchable]="false" formControlName="method">
        <ng-option *ngFor="let item of availableMethods" [value]="item">{{ item }}</ng-option>
      </ng-select>
    </div>
    <div class="form-group">
      <label for="callbackUrl">{{ 'LiveChat::AgentMessagingSettingsCallbackUrlInfo' | abpLocalization }}</label>
      <input id="callbackUrl" type="text" class="form-control" formControlName="callbackUrl" />
    </div>
    <div *ngIf="showCredentialFields">
      <div class="form-group">
        <label for="identityUrl">{{ 'LiveChat::AgentMessagingSettingsIdentityUrl' | abpLocalization }}</label>
        <input id="identityUrl" type="text" class="form-control" formControlName="identityUrl" />
      </div>
      <div class="form-group">
        <label for="clientId">{{ 'LiveChat::AgentMessagingSettingsClientId' | abpLocalization }}</label>
        <input id="clientId" type="password" class="form-control" formControlName="clientId" />
      </div>
      <div class="form-group">
        <label for="clientSecret">{{ 'LiveChat::AgentMessagingSettingsClientSecret' | abpLocalization }}</label>
        <input id="clientSecret" type="password" class="form-control" formControlName="clientSecret" />
      </div>
      <div class="form-group">
        <label for="scope">{{ 'LiveChat::AgentMessagingSettingsScope' | abpLocalization }}</label>
        <input id="scope" type="text" class="form-control" formControlName="scope" />
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12">
      <hr />
      <div>
        <abp-button iconClass="fa fa-save" (click)="save()" [disabled]="form?.invalid">
          {{ 'AbpUi::Save' | abpLocalization }}
        </abp-button>
      </div>
    </div>
  </div>
</div>
