import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/chat-dashboard',
      iconClass: 'fa fa-chart-line',
      name: '::Menu:ChatDashboard',
      order: 1,
      parentName: '::Menu:Dashboards',
      requiredPolicy: 'LiveChat.ChatMetrics',
    },
  ]);
};

export const CHAT_DASHBOARD_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
