<div class="row">
  <div class="col-md-12 float-left">
    <b>{{ '::ProductVersion' | abpLocalization }}:</b>
    <span> <i *ngIf="loading" class="fa fa-spinner fa-pulse fa-fw ms-1"></i> {{ productVersion }} </span>
  </div>
</div>

<br />

<p (click)="toggleDetails()" style="text-decoration: underline; cursor: pointer">
  {{ (isDetailsVisible ? '::HideDetails' : '::ShowDetails') | abpLocalization }}
</p>
<div *ngIf="isDetailsVisible">
  <div *ngFor="let pod of appSetDto?.pods" class="row">
    <div class="col-md-12 float-left">
      <b>{{ pod?.app }}:</b> {{ pod?.tag }}
    </div>
  </div>
</div>
