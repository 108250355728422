import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class AgentMessagingSettingsService {
  constructor(private restService: RestService) {}

  getSettings(): Observable<void> {
    return this.restService.request<void, void>({
      method: 'GET',
      url: '/api/app/agent-messaging-settings',
    });
  }

  updateSettings(body): Observable<void> {
    return this.restService.request<void, void>({
      method: 'PUT',
      url: '/api/app/agent-messaging-settings',
      body,
    });
  }
}
