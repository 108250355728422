import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/skills',
      iconClass: 'fas fa-file-alt',
      name: '::Menu:Skills',
      order: 303,
      parentName: '::Menu:AgentManagement',
      requiredPolicy: 'LiveChat.Skills',
    },
  ]);
};

export const SKILLS_SKILL_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
