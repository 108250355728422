import { eSaasComponents, SaasEntityPropContributors } from '@volo/abp.ng.saas';
import { SaasTenantDto } from '@volo/abp.ng.saas/proxy';
import { EntityProp, EntityPropList, ePropType } from '@abp/ng.theme.shared/extensions';

const tenantIdProp = new EntityProp<SaasTenantDto>({
  type: ePropType.String,
  name: 'id',
  displayName: '::Saas:TenantId',
  columnWidth: 300,
});

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function tenantIdContributor(propList: EntityPropList<SaasTenantDto>) {
  propList.addTail(tenantIdProp);
}

export const tenantEntityPropContributors: SaasEntityPropContributors = {
  [eSaasComponents.Tenants]: [tenantIdContributor],
};
