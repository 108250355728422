import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/agent-chat',
      iconClass: 'fas fa-comments',
      order: 1,
      name: '::Menu:Chat',
      requiredPolicy: 'LiveChat.Chats',
    },
  ]);
};

export const CHAT_APPLICATION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
