import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/',
      name: '::Menu:Home',
      order: 0,
      iconClass: 'fas fa-home',
      layout: eLayoutType.application,
      invisible: true,
    },
    {
      name: '::Menu:Dashboards',
      order: 0,
      iconClass: 'fas fa-chart-line',
      layout: eLayoutType.application,
      requiredPolicy: 'LiveChat.ChatMetrics || LiveChat.Monitoring',
    },
    {
      name: '::Menu:AgentManagement',
      order: 3,
      iconClass: 'fas fa-users',
      layout: eLayoutType.application,
      requiredPolicy: 'LiveChat.Agents || LiveChat.States || LiveChat.Skills',
    },
  ]);
};

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
